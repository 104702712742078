<!-- 首页 f-wrap-->
<template>
    <div class="page-dya-deta f-wrap">
        <div class="deta-head">
            <router-link :to="'/popularBusiness/business?id='+details.user_business_id" >
                <div class="avtar f-ibm">
                    <img :src="details.head_img" alt="" />
                </div>
                <div  class="rit f-ibm" >
                    <div class="naickname f-f0">
                        <div class="text f-ibm">{{details.name}}</div>
                        <i class="icon-shang indet f-ibm"></i>
                        <i class="icon-zuan indet f-ibm" v-if="details.vip==2"></i>
                    </div>
                    <div class="time">29分钟前</div>
                </div>
            </router-link>
        </div>
        <div class="deta-cont">
            <div class="text">{{details.title}}</div>
           <div v-if="details.video_url">
           <video :src="details.video_url" :controls="videoOptions.controls" class="videocss video-js vjs-big-play-centered vjs-fluid" webkit-playsinline="true"  playsinline="true" x-webkit-airplay="allow"
                x5-playsinline
                style="width: 100%;"
                @play="onPlayerPlay"
                @pause="onPlayerPause"
                autoplay="autoplay"
                ref="video">
            </video>
           </div>
           <div v-else>
            <div class="img" v-for="item in details.photo" :key="item">
                <img :src="item" />
            </div>
           </div>

            
        </div>
        
       
    </div>
</template>



<script>
import { indexApi } from "@/api/module.js";

import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";

// 分割处理
 function strCutFun(data,str){
     var _data = data;
     return _data.split(str);
 }
export default {
    name: "dynamDetails",
    data() {
        return {
            details:{},  //详情
            videoOptions: {
                    controls:true,
                    src: '', // url地址
                },
                player: null,
                playTime:'',
                seekTime:'',
                current:'',
        };
    },
    created(){
        
        var _id = this.$route.query.dynamic_id;
        console.log(this.$route.query)
        if(!_id) return;
         indexApi
                .getdynamDetails({
                    dynamic_id: _id
                })
                .then((res) => {
                    console.log(res.data.data);
                    if (res.data.data) {
                        var _data = res.data.data;
                        _data.photo = strCutFun(_data.photo,"|");
                        this.details = _data;
                        this.videoOptions.src = this.details.video_url;
                       console.log(_data)
                    }
                });
    },
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {
        this.initVideo();
    },
    methods: {
        initVideo() {
                //原生初始化视频方法
                let myVideo = this.$refs.video;
                //ontimeupdate
                  myVideo.ontimeupdate = function() {myFunction()};
                  let _this = this;

                  function myFunction(){
                      let playTime = myVideo.currentTime
                      setTimeout(function () {
                          localStorage.setItem("cacheTime",playTime)
                      },500)
                      let time = localStorage.getItem("cacheTime")
                      // 当前播放位置发生变化时触发。
                      if(playTime - Number(time) > 2){
                          myVideo.currentTime = Number(time)
                      }else{
                      }
                  };
        },
        // 播放回调
        onPlayerPlay(player) {
            // this.globalSetting = true
            console.log("player play!", player);
            // document.getElementsByClassName("vjs-control-bar").style.display = "block";
            // document.getElementsByClassName("vjs-control-bar").style.display = "block";
        },

        // 暂停回调
        onPlayerPause(player) {
            // this.globalSetting.controls = false;
            // console.log("player pause!", player);
            // var video = document.getElementById("video");
            // video.controls=false;
            // document.getElementsByClassName("vjs-control-bar").style.display = "none";
        },

        
    },
    beforeDestroy() {
            if (this.player) {
                this.player.dispose()
            }
    }
};
</script>

<style lang="less" scoped>
.videocss{
    height: 500px;

}
.page-dya-deta{
    background: white;
    border: 1px solid #E5E5E5;
    margin-top: 24px;
    padding: 0 30px;
    box-sizing: border-box;
    .deta-head{
        padding: 20px 0;
        border-bottom: 1px solid #E5E5E5;
        .avtar{
            width: 80px;
            height: 80px;
            border-radius: 50%;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .icon-shang{
            width: 18px;
            height: 18px;
            background: url(../assets/pc/seller/shang@2x.png) no-repeat;
            background-size: 100% 100%;
        }
        .icon-zuan{
            width: 18px;
            height: 18px;
            background: url(../assets/pc/seller/zuan@2x.png) no-repeat;
            background-size: 100% 100%;
        }
        .indet{
            margin-left: 5px;
        }
        .rit{
            margin-left: 18px;
            .text{
                margin-right: 10px;
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #E61414;
            }
            .time{
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #AAAAAA;
                margin-top: 10px;
            }
        }
    }
    .deta-cont{
        padding: 30px 0;
        .text{
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #444444;
        }
        .img{
            width: 100%;
            overflow: hidden;
            text-align: center;
            img{
                max-width: 100%;
                width: 100%;
                height: auto;
                margin-top: 30px;
            }
        }
    }
}

@media screen and (max-width:750px) { 

    .page-dya-deta{
    border: none;
    border-top: 1px solid #E5E5E5;
    margin-top: 0px;
    padding: 0 10px 28px;
    box-sizing: border-box;
    .deta-head{
        padding: 18px 0;
        border-bottom:none;
        .avtar{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .icon-shang{
            width: 15px;
            height: 15px;
            background: url(../assets/pc/seller/shang@2x.png) no-repeat;
            background-size: 100% 100%;
        }
        .icon-zuan{
            width: 15px;
            height: 15px;
            background: url(../assets/pc/seller/zuan@2x.png) no-repeat;
            background-size: 100% 100%;
        }
        .indet{
            margin-left: 6px;
        }
        .rit{
            margin-left: 10px;
            .text{
                margin-right: 8px;
                font-size: 15px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #E61414;
                line-height: 15px;
            }
            .time{
                font-size: 11px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #AAAAAA;
                margin-top: 10px;
                line-height: 11px;
            }
        }
    }
    .deta-cont{
        padding: 0;
        .text{
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #444444;
        }
        .img{
            width: 100%;
            overflow: hidden;
            text-align: center;
            img{
                max-width: 100%;
                width: 100%;
                height: auto;
                margin-top: 12px;
            }
        }
    }
}

}
</style>
